const percentFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
})
const numberFormatter = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})
const currencyFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  useGrouping: true,
})
const currencyDiffFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  useGrouping: true,
  signDisplay: 'exceptZero',
})
const integerFormatter = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export function formatPercent(value?: number | null) {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return '-'
  }
  return formatRatio(value / 100)
}
export function formatNumber(value?: number | null) {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return '-'
  }
  return numberFormatter.format(value)
}
export function formatRatio(value?: number | null) {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return '-'
  }
  return percentFormatter.format(value)
}
export function formatPrice(value?: number | null) {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return '-'
  }

  // convert -0 to 0
  value += 0

  return currencyFormatter.format(value ?? 0).replace('.00', '')
}

export function formatPriceDiff(value?: number | null) {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return '-'
  }
  if (value === 0) {
    return '+0,00 €'
  }
  return currencyDiffFormatter.format(value).replace('.00', '')
}
export function formatInteger(value?: number) {
  if (value === undefined || Number.isNaN(value)) {
    return '-'
  }
  return integerFormatter.format(value ?? 0)
}
export function parseFloatInput<T = undefined>(value?: string | number, defaultValue?: T): number | T {
  if (value === undefined || value === null) {
    return defaultValue as T
  }
  const float = Number.parseFloat(value.toString().replace(',', '.'))
  if (Number.isNaN(float)) {
    return defaultValue as T
  }
  return float
}
